<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Drive Folder Permission Manager</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>This page shows all of the Google Drive folders that {{ isAdmin ? 'have been' : 'you have' }} set up to receive uploads within the form system.</p>
    </v-card-text>
    <v-data-table :items="folders" :headers="headers">
      <template v-slot:item.name="{ item }">
        <a target="_blank" :href="'https://drive.google.com/drive/u/0/folders/' + item.folderId">{{ item.name }}</a>
      </template>
      <template v-slot:item.forms="{ item }">
        <v-tooltip v-for="{ _id, name, status } of item.forms" :key="item._id + '-' + _id" top>
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" :to="'/forms/admin/' + _id" :outlined="status === 'Inactive'" color="success" small label>{{ name }}</v-chip>
          </template>
          <span>Form status: {{ status }}; click to access this form</span>
        </v-tooltip>
        <template v-if="'libraryArchive' in item && Array.isArray(item.libraryArchive)">
          <v-chip v-for="{ name } of item.libraryArchive" :key="item._id + '-' + name.replace(' ', '-')" :to="'/library/archives/' + name.replace(' ', '%20')" color="info" small label>Library Archive Section: {{ name }}</v-chip>
        </template>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn icon @click=editEntry(item)>
          <v-icon>fal fa-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="editDialog" width="400">
      <v-card>
        <v-card-title>Edit Folder Permissions</v-card-title>
        <v-card-text>
          <p>Here you can update the username used by the form to upload the files to the requested folder. The account must have edit permissions on the drive.</p>
          <v-text-field v-model="editUser" label="User Account" outlined hide-details></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="editDialog = false">Cancel</v-btn>
          <v-btn color="success" text @click="updateEntry">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="selectedFolderIsInUse" color="error" text @click="removeEntry">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { onMounted, ref, computed } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => user.value.roles.includes('Technology Services'))
    const folders = ref([])
    const headers = computed(() => {
      const arr = [
        { text: 'Folder Name', value: 'name' },
        { text: 'Usage (Forms/Archive Section)', value: 'forms' }
      ]
      if (isAdmin.value) {
        arr.splice(1, 0, { text: 'Permission User', value: 'user' })
        arr.push({ text: 'Edit', value: 'edit' })
      }
      return arr
    })

    const service = root.$feathers.service('system/drive-upload-perm')

    async function loadFolders () {
      const aggregate = [
        { $match: { user: user.value.username } },
        { $lookup: {
          from: 'Forms-Revision',
          localField: 'folderId',
          foreignField: 'sections.inputs.file.folder',
          as: 'revs'
        } },
        { $lookup: {
          from: 'Forms-Base',
          localField: 'revs.form',
          foreignField: '_id',
          as: 'forms'
        } },
        { $project: {
          _id: 1,
          name: 1,
          folderId: 1,
          user: { $concat: ['$user', '@covenant.edu'] },
          forms: { $sortArray: { input: '$forms', sortBy: { name: 1 } } }
        } },
        { $lookup: {
          from: 'Library-ArchiveCategories',
          localField: 'folderId',
          foreignField: 'folder',
          as: 'libraryArchive',
          pipeline: [{ $project: { name: 1 } }]
        } },
        { $sort: { user: 1, name: 1 } }
      ]
      if (isAdmin.value) {
        aggregate.splice(0, 1)
      }
      const { data } = await service.find({ query: { aggregate } })
      folders.value = data
    }
    onMounted(() => {
      loadFolders()
    })

    const editDialog = ref(false)
    const editId = ref('')
    const editUser = ref('')
    const selectedFolderIsInUse = computed(() => editId.value === '' ? false : folders.value.filter(({ _id, forms, libraryArchive }) => _id === editId.value && (forms.filter(({ status }) => status !== 'Inactive').length > 0 || libraryArchive.length > 0)).length > 0)
    function editEntry (obj) {
      editId.value = obj._id
      editUser.value = obj.user
      editDialog.value = true
    }
    async function updateEntry () {
      let user = editUser.value.replaceAll(/@covenant.edu/ig, '')
      await root.$feathers.service('system/drive-upload-perm').patch(editId.value, { user })
      await loadFolders()
      editDialog.value = false
      editId.value = ''
      editUser.value = ''
    }
    async function removeEntry () {
      if (confirm('Are you sure you want to remove the entry for this Google Drive folder? Uploads from the portal to this folder will no longer work. Make sure this is not used in any forms or anywhere in the Portal before removing it.')) {
        for (let i = 0; i < folders.value.length; i++) {
          if (folders.value[i]._id === editId.value) {
            await root.$feathers.service('system/drive-upload-perm').remove(editId.value)
            folders.value.splice(i, 1)
            break
          }
        }
        editDialog.value = false
        editId.value = ''
        editUser.value = ''
      }
    }

    return {
      user,
      isAdmin,
      folders,
      headers,
      editDialog,
      editId,
      editUser,
      selectedFolderIsInUse,
      editEntry,
      updateEntry,
      removeEntry
    }
  }
}
</script>
