var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Drive Folder Permission Manager")])],1),_c('v-card-text',[_c('p',[_vm._v("This page shows all of the Google Drive folders that "+_vm._s(_vm.isAdmin ? 'have been' : 'you have')+" set up to receive uploads within the form system.")])]),_c('v-data-table',{attrs:{"items":_vm.folders,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":'https://drive.google.com/drive/u/0/folders/' + item.folderId}},[_vm._v(_vm._s(item.name))])]}},{key:"item.forms",fn:function(ref){
var item = ref.item;
return [_vm._l((item.forms),function(ref){
var _id = ref._id;
var name = ref.name;
var status = ref.status;
return _c('v-tooltip',{key:item._id + '-' + _id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"to":'/forms/admin/' + _id,"outlined":status === 'Inactive',"color":"success","small":"","label":""}},on),[_vm._v(_vm._s(name))])]}}],null,true)},[_c('span',[_vm._v("Form status: "+_vm._s(status)+"; click to access this form")])])}),('libraryArchive' in item && Array.isArray(item.libraryArchive))?_vm._l((item.libraryArchive),function(ref){
var name = ref.name;
return _c('v-chip',{key:item._id + '-' + name.replace(' ', '-'),attrs:{"to":'/library/archives/' + name.replace(' ', '%20'),"color":"info","small":"","label":""}},[_vm._v("Library Archive Section: "+_vm._s(name))])}):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editEntry(item)}}},[_c('v-icon',[_vm._v("fal fa-pencil")])],1)]}}])}),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Edit Folder Permissions")]),_c('v-card-text',[_c('p',[_vm._v("Here you can update the username used by the form to upload the files to the requested folder. The account must have edit permissions on the drive.")]),_c('v-text-field',{attrs:{"label":"User Account","outlined":"","hide-details":""},model:{value:(_vm.editUser),callback:function ($$v) {_vm.editUser=$$v},expression:"editUser"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.updateEntry}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.selectedFolderIsInUse,"color":"error","text":""},on:{"click":_vm.removeEntry}},[_vm._v("Remove")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }